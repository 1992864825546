import React, { useState, useEffect } from 'react';
import axios from '../api/myAxios';
import { useAuth } from '../contexts/AuthContext';
import '../styles/UserProfile.css';

const UserProfile = () => {
  const { currentUser } = useAuth();
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUserProfile();
  }, [currentUser]);

  const fetchUserProfile = async () => {
    try {
      setError(null);
      const { data } = await axios.get('/api/users/profile');
      setProfile(data.user);
    } catch (error) {
      console.error('Error fetching profile:', error);
      setError('Failed to load profile');
    } finally {
      setLoading(false);
    }
  };

  const handlePhotoUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      setError(null);
      setUploading(true);
      const formData = new FormData();
      formData.append('photo', file);

      const { data } = await axios.post('/api/users/profile-photo', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      await fetchUserProfile();
    } catch (error) {
      console.error('Error uploading photo:', error);
      setError('Failed to upload photo');
    } finally {
      setUploading(false);
    }
  };

  if (loading) {
    return (
      <div className="profile-container">
        <div className="loading">
          <div className="loading-spinner" />
        </div>
      </div>
    );
  }

  return (
    <div className="profile-container">
      {error && <div className="error-message">{error}</div>}
      <div className="profile-card">
        <div className="avatar-container">
          {profile?.photoURL ? (
            <img
              src={profile.photoURL}
              alt={profile.displayName || 'User'}
              className="avatar"
            />
          ) : (
            <div className="avatar-placeholder">
              {profile?.displayName?.[0]?.toUpperCase() || '?'}
            </div>
          )}
          <div className="avatar-upload">
            <label htmlFor="avatar-input">
              <input
                id="avatar-input"
                type="file"
                accept="image/*"
                onChange={handlePhotoUpload}
                disabled={uploading}
              />
              <button
                className="upload-button"
                disabled={uploading}
                onClick={() => document.getElementById('avatar-input').click()}
              >
                ✏️
              </button>
            </label>
          </div>
        </div>

        <h1 className="profile-name">
          {profile?.displayName || 'User'}
        </h1>
        <div className="profile-email">
          {profile?.email}
        </div>
        
        <div className="profile-info">
          <div className="info-item">
            Member since: {new Date(profile?.createdAt).toLocaleDateString()}
          </div>
          <div className="info-item">
            Last login: {new Date(profile?.lastLogin).toLocaleDateString()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
