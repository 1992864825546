import React, { useState, useEffect } from 'react';
import axios from '../api/myAxios';

const DataList = ({ workspaceId }) => {
    const [dataItems, setDataItems] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        if (workspaceId) {
          fetchDataItems();
        }
    }, [workspaceId]);

    const fetchDataItems = async () => {
        try {
            const response = await axios.get(`/api/data/${workspaceId}`);
            setDataItems(response.data);
        } catch (error) {
            console.error('Error fetching data items:', error);
            setError('Error fetching data items');
        }
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`/api/data/${id}`);
            fetchDataItems();
        } catch (error) {
            console.error('Error deleting data item:', error);
            setError('Error deleting data item');
        }
    };

    return (
        <div>
            <h3>Your Uploaded Data</h3>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {dataItems.length === 0 ? (
                <p>No data uploaded yet.</p>
            ) : (
                <ul>
                {dataItems.map((item) => (
                    <li key={item._id}>
                    <a href={item.fileUrl} target="_blank" rel="noopener noreferrer">
                        {item.description || 'View File'}
                    </a>
                    <button onClick={() => handleDelete(item._id)}>Delete</button>
                    </li>
                ))}
                </ul>
            )}
        </div>
    );
};

export default DataList;