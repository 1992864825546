import React from 'react';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';

function LogoutButton() {
  const navigate = useNavigate();

  const handleLogout = () => {
    auth.signOut().then(() => {
      navigate('/login');
    });
  };

  return <button onClick={handleLogout}>Logout</button>;
}

export default LogoutButton;