import React, { useState } from 'react';
import axios from '../api/myAxios';
import '../styles/ChatWindow.css';
import ChatHeader from './ChatHeader';
import MessageList from './MessageList';
import MessageInput from './MessageInput';

function ChatWindow({ subdomain }) {
    const [messages, setMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // Function to handle sending a new message
    const handleSendMessage = async (messageText) => {
        const userMessage = {
            sender: 'user',
            text: messageText,
            timestamp: new Date().toISOString(),
        };
        setIsLoading(true);

        // Update messages state with the new user message
        setMessages((prevMessages) => [...prevMessages, userMessage]);

        // Call the backend API to get the AI response
        try {
            const response = await axios.post('/public/chat', {
                message: messageText,
                subdomain: subdomain,
            });

            const aiMessage = {
                sender: 'ai',
                text: response.data.reply,
                timestamp: new Date().toISOString(),
            };

            // Update messages state with the AI message
            setMessages((prevMessages) => [...prevMessages, aiMessage]);
        } catch (error) {
            console.error('Error getting AI response:', error);
            // Optionally, display an error message in the chat
            const errorMessage = {
                sender: 'ai',
                text: 'Sorry, there was an error processing your message.',
                timestamp: new Date().toISOString(),
            };
            setMessages((prevMessages) => [...prevMessages, errorMessage]);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="chat-window">
            <ChatHeader subdomain={subdomain} />
            <MessageList messages={messages} />
            <MessageInput onSendMessage={handleSendMessage} />
            {isLoading && <p>Loading...</p>}
        </div>
    );
}

export default ChatWindow;