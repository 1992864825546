import React, { useState } from "react"
import { auth } from "../firebase"
import { useNavigate } from "react-router-dom"
import { useCookies } from 'react-cookie'

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(['authToken']);

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            // 1. Sign in with Firebase Auth
            const userCredential = await auth.signInWithEmailAndPassword(email, password);
            const user = userCredential.user;

            // 2. Get the ID token
            const idToken = await user.getIdToken();

            setCookie('authToken', idToken, {
                domain: '.get-to-know.me',
                path: '/',
                secure: true,
                sameSite: 'lax',
            });

            navigate('/dashboard');
        } catch (error) {
            alert('Error logging in:', error);
        }
    };

    return (
        <form onSubmit={handleLogin}>
            <h2>Login</h2>
            <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
            />
            <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                required
            />
            <button type="submit">Login</button>
        </form>
    );
}

export default Login;