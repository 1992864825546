import React, { useState } from 'react';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import axios from '../api/myAxios';

function SignUp() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSignUp = async (e) => {
        e.preventDefault();
        try {
            // Create Firebase user
            const userCredential = await auth.createUserWithEmailAndPassword(email, password);
            const user = userCredential.user;

            // Update display name in Firebase
            await user.updateProfile({
                displayName: displayName
            });

            // Create user profile in our backend
            await axios.post('/api/users', {
                uid: user.uid,
                email: user.email,
                displayName: displayName
            });

            navigate('/dashboard');
        } catch (error) {
            console.error('Error signing up:', error);
            setError(error.message);
        }
    };

    return (
        <div className="signup-container">
            <form onSubmit={handleSignUp}>
                <h2>Sign Up</h2>
                {error && <div className="error-message">{error}</div>}
                <input
                    type="text"
                    value={displayName}
                    onChange={(e) => setDisplayName(e.target.value)}
                    placeholder='Display Name'
                    required
                />
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder='Email'
                    required
                />
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder='Password'
                    required
                />
                <button type="submit">Sign Up</button>
            </form>
        </div>
    );
}

export default SignUp;