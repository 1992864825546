import React from 'react';
import ChatWindow from './ChatWindow';
import '../styles/PersonalizedPage.css';

function PersonalizedPage({ subdomain }) {
  console.log("PERSONALIZED PAGE");
  return (
    <div className="personalized-page">
      <h1>Welcome to {subdomain}'s personalized page!</h1>
      <div className="chat-container">
        <ChatWindow subdomain={subdomain} />
      </div>
    </div>
  );
}

export default PersonalizedPage;