// src/components/MessageInput.js
import React, { useState } from 'react';

function MessageInput({ onSendMessage }) {
  const [messageText, setMessageText] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (messageText.trim() === '') return;
    onSendMessage(messageText);
    setMessageText('');
  };

  return (
    <form className="message-input" onSubmit={handleSubmit}>
      <input
        type="text"
        value={messageText}
        onChange={(e) => setMessageText(e.target.value)}
        placeholder="Type your message..."
        required
      />
      <button type="submit">Send</button>
    </form>
  );
}

export default MessageInput;