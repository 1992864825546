export function getSubdomain() {
    const hostname = window.location.hostname; // e.g., 'user1.get-to-know.me' or 'get-to-know.me'
    const parts = hostname.split('.');

    // Handle subdomain.localhost format
    if (parts.length === 2 && parts[1] === 'localhost') {
        return parts[0];
    }

    // For domains like 'get-to-know.me' (no subdomain) or plain localhost
    if (hostname === 'localhost' || hostname === '127.0.0.1' || parts.length <= 2) {
        return null;
    }

    // For subdomains like 'user1.get-to-know.me'
    return parts.slice(0, parts.length - 2).join('.');
}