import React from 'react';

function ChatHeader({ workspaceId }) {
  return (
    <div className="chat-header">
      <h2>Chat Interface</h2>
      {workspaceId && <p>Workspace ID: {workspaceId}</p>}
    </div>
  );
}

export default ChatHeader;