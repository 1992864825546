import axios from 'axios';
import { auth } from '../firebase';

const instance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
});

instance.interceptors.request.use(
    async (config) => {
        const token = await auth.currentUser?.getIdToken();
        if (token) {
            config.headers.authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

export default instance;

