import React, { useState } from "react";
import axios from "../api/myAxios";

const DataUploadForm = ({ workspaceId }) => {
    const [file, setFile] = useState(null);
    const [description, setDescription] = useState('');
    const [error, setError] = useState('');

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
        setError('');
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handleUpload = async (e) => {
        e.preventDefault();

        if (!file) {
            setError('Please select a file to upload.');
            return;
        }

        if (file.size > 5 * 1024 * 1024) {
            setError('File size is too large. Maximum size is 5MB.');
            return;
        }

        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('description', description);
            formData.append('workspaceId', workspaceId);

            const response = await axios.post('/api/data/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            console.log('File uploaded successfully:', response.data);

            // reset form
            setFile(null);
            setDescription('');
        } catch (error) {
            console.error('Error uploading file:', error);
            setError('Error uploading file. Please try again.');
        }
    };
    
    return (
        <form onSubmit={handleUpload}>
            <h3>Upload Your Data</h3>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <input type="file" onChange={handleFileChange} accept=".pdf,.doc,.docx,.txt" />
            <input
                type="text"
                value={description}
                onChange={handleDescriptionChange}
                placeholder="Description"
            />
            <button type="submit">Upload</button>
        </form>
    );
}

export default DataUploadForm;