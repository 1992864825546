import React, { useState, useEffect } from 'react';
import axios from '../api/myAxios';

const WorkspaceList = ({ onSelect }) => {
    const [workspaces, setWorkspaces] = useState([]);
    const [newWorkspaceName, setNewWorkspaceName] = useState('');
    const [newWorkspaceDescription, setNewWorkspaceDescription] = useState('');
    const [error, setError] = useState('');
    const [selectedWorkspace, setSelectedWorkspace] = useState(null);

    useEffect(() => {
        fetchWorkspaces();
    }, []);

    const fetchWorkspaces = async () => {
        try {
            const response = await axios.get('/api/workspaces');
            setWorkspaces(response.data);
        } catch (error) {
            console.log('Error fetching workspaces:', error);
            setError('Error fetching workspaces. Please try again.');
        }
    };

    const handleCreateWorkspace = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/workspaces', {
                name: newWorkspaceName,
                description: newWorkspaceDescription,
            });

            setWorkspaces([...workspaces, response.data.workspace]);
            setNewWorkspaceName('');
            setNewWorkspaceDescription('');
        } catch (error) {
            console.log('Error creating workspace:', error);
            setError('Error creating workspace. Please try again.');
        }
    };

    const handleDeleteWorkspace = async (id) => {
        try {
            await axios.delete(`/api/workspaces/${id}`);
            setWorkspaces(workspaces.filter((workspace) => workspace._id !== id));
            // If the deleted workspace was selected, reset selection
            if (selectedWorkspace && selectedWorkspace._id === id) {
                setSelectedWorkspace(null);
                onSelect(null);
            }
        } catch (error) {
            console.error('Error deleting workspace:', error);
            setError('Failed to delete workspace. Please try again.');
        }
    };

    const handleSelectWorkspace = (workspace) => {
        setSelectedWorkspace(workspace);
        onSelect(workspace);
    };

    return (
        <div>
          <h3>Your Workspaces</h3>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <form onSubmit={handleCreateWorkspace}>
            <input
              type="text"
              value={newWorkspaceName}
              onChange={(e) => setNewWorkspaceName(e.target.value)}
              placeholder="Workspace Name"
              required
            />
            <input
              type="text"
              value={newWorkspaceDescription}
              onChange={(e) => setNewWorkspaceDescription(e.target.value)}
              placeholder="Description"
            />
            <button type="submit">Create Workspace</button>
          </form>
          {workspaces.length === 0 ? (
            <p>No workspaces yet.</p>
          ) : (
            <ul>
              {workspaces.map((workspace) => (
                <li key={workspace._id}>
                  <button onClick={() => handleSelectWorkspace(workspace)}>
                    {workspace.name}
                  </button>
                  <button onClick={() => handleDeleteWorkspace(workspace._id)}>Delete</button>
                </li>
              ))}
            </ul>
          )}
        </div>
    );
};

export default WorkspaceList;